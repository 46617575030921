import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./ListProducts.css";
import { BarangModel } from "../../Models/BarangModel";
import { fetchBarangFromSubKategori } from "../../Controllers/ExcelController";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import LoadingComponent from "../Common/Loading";
import SearchBar from "../Categories/SearchBar";

/**
 * Render the header component.
 *
 * @return {JSX.Element} The header component JSX.
 */
function Header(
  subtitle: string,
  isMobile: boolean,
  version: string
): JSX.Element {
  return (
    <Row style={{ marginBottom: "20px" }}>
      <Col
        xs="12"
        style={{
          backgroundColor: "#FFD000",
          padding: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col xs="1"></Col>
        <Link to="/">
          <img
            loading="lazy"
            src={`/assets/images/Oribaru.png?version=${version}`}
            alt="Logo"
            style={{ width: "70px", height: "50px", marginRight: "10px" }}
          />
        </Link>
        <div
          style={{
            textAlign: "center",
            flexGrow: "1",
            marginLeft: isMobile ? "0" : "30px",
          }}
        >
          <h6
            style={{
              margin: "0",
              marginBottom: "10px",
              fontSize: isMobile ? "18px" : "20px",
            }}
          >
            PRODUCT
          </h6>
          <h2 style={{ margin: "0", fontSize: isMobile ? "20px" : "" }}>
            {subtitle}
          </h2>
        </div>
        <SearchBar isMobile={isMobile} isHome={false} />
      </Col>
    </Row>
  );
}

/**
 * Renders a component that displays a list of products.
 *
 * @return {JSX.Element} A React component that displays a list of products.
 */
function ListProducts() {
  const [barangList, setBarangList] = useState<BarangModel[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState("");

  const kategori = useRef("");
  const subkategori = useRef("");

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // adjust the threshold value as needed
    }
    const getCurrentVersionFromStorage = async () => {
      var versionStorage = localStorage.getItem("photoVersion");
      if (versionStorage !== null) {
        setVersion(versionStorage);
      }
    };

    getCurrentVersionFromStorage();
    window.addEventListener("resize", handleResize);

    handleResize(); // initialize the state value on mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [version]);

  useEffect(() => {
    const getKategoriAndSubKategoriStorage = async () => {
      const kategoriStorage = localStorage.getItem("kategoriStorage");
      if (kategoriStorage !== null) {
        kategori.current = kategoriStorage;
      }

      const subKategoriStorage = localStorage.getItem("subKategoriStorage");
      if (subKategoriStorage !== null) {
        subkategori.current = subKategoriStorage;
      }
    };

    const fetchBarangList = async () => {
      const result = await fetchBarangFromSubKategori(
        kategori.current,
        subkategori.current
      );
      setBarangList(result);
    };

    getKategoriAndSubKategoriStorage();
    fetchBarangList();
    setIsLoading(false);
  }, []);

  const handleClick = (photoPath: string, codeParts: string, motor: string) => {
    var htmlTag: string;

    htmlTag = `
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <img loading="lazy" src="${photoPath}" style="width: 80%; margin-bottom: 20px;" />
      <div style="text-align: center;">
        ${codeParts} <br />
        <b>${motor}</b>
      </div>
    </div>
  `;

    if (isMobile) {
      htmlTag = `
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <img loading="lazy" src="${photoPath}" style="height: 100%;" />
      <div style="text-align: center;">
        ${codeParts} <br />
        <b>${motor}</b>
      </div>
    </div>
  `;
    }

    swal.fire({
      html: htmlTag,
      showCloseButton: true,
      showConfirmButton: false,
      heightAuto: false,
    });
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isMobile === false) {
    return (
      <Col>
        {Header(subkategori.current, isMobile, version)}
        <Container>
          {(() => {
            const elements: JSX.Element[] = [];
            var element: JSX.Element;
            var row = 0;
            for (let i = 0; i < barangList.length; i = i + 4) {
              try {
                if (row % 2 === 0) {
                  element = (
                    <Row
                      style={{ paddingBottom: "10px", marginTop: "10px" }}
                      key={"row" + i}
                    >
                      <Col xs="1"></Col>
                      <Col
                        xs="4"
                        key={i}
                        role="button"
                        onClick={() =>
                          handleClick(
                            barangList[i].photoPath,
                            barangList[i].codeParts,
                            barangList[i].motor
                          )
                        }
                      >
                        <div className="left-content">
                          <Card
                            style={{
                              borderRadius: "0",
                              backgroundColor: "transparent", //#FAFAFA
                              border: "0px",
                              marginLeft: "30px",
                            }}
                          >
                            <Card.Img
                              loading="lazy"
                              variant="bottom"
                              src={barangList[i].photoPath}
                              style={{
                                height: "60vh",
                                objectFit: "contain",
                                paddingTop: "50px",
                              }}
                            />

                            <Card.Text
                              style={{
                                position: "absolute",
                                left: "1%",
                                top: "1%",
                                color: "black",
                                width: "70%",
                                fontSize: "15px",
                              }}
                            >
                              {barangList[i].codeParts} <br />
                              <b>{barangList[i].motor}</b>
                            </Card.Text>
                          </Card>
                        </div>
                      </Col>
                      <Col xs="6" key={i + 1}>
                        {i + 1 < barangList.length ? (
                          <Col
                            style={{ paddingBottom: "10px" }}
                            role="button"
                            onClick={() =>
                              handleClick(
                                barangList[i + 1].photoPath,
                                barangList[i + 1].codeParts,
                                barangList[i + 1].motor
                              )
                            }
                          >
                            <div className="right-row">
                              <Card
                                style={{
                                  borderRadius: "0",
                                  backgroundColor: "transparent", //#FAFAFA
                                  border: "0px",
                                  marginLeft: "30px",
                                }}
                              >
                                <Card.Img
                                  loading="lazy"
                                  variant="bottom"
                                  src={barangList[i + 1].photoPath}
                                  style={{
                                    height: "30vh",
                                    objectFit: "contain",
                                    paddingTop: "50px",
                                    marginLeft: "20%",
                                  }}
                                />

                                <Card.Text
                                  style={{
                                    position: "absolute",
                                    left: "1%",
                                    top: "1%",
                                    color: "black",
                                    width: "70%",
                                    fontSize: "15px",
                                  }}
                                >
                                  {barangList[i + 1].codeParts} <br />
                                  <b>{barangList[i + 1].motor}</b>
                                </Card.Text>
                              </Card>
                            </div>
                          </Col>
                        ) : null}
                        <Row>
                          {i + 2 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() => {
                                handleClick(
                                  barangList[i + 2].photoPath,
                                  barangList[i + 2].codeParts,
                                  barangList[i + 2].motor
                                );
                              }}
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 2].photoPath}
                                    style={{
                                      height: "30vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "70%",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {barangList[i + 2].codeParts} <br />
                                    <b>{barangList[i + 2].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                          {i + 3 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i + 3].photoPath,
                                  barangList[i + 3].codeParts,
                                  barangList[i + 3].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 3].photoPath}
                                    style={{
                                      height: "30vh",
                                      objectFit: "cover",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "70%",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {barangList[i + 3].codeParts} <br />
                                    <b>{barangList[i + 3].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                      <Col xs="1"></Col>
                    </Row>
                  );
                } else {
                  element = (
                    <Row style={{ paddingBottom: "10px" }} key={"row" + i}>
                      <Col xs="1"></Col>
                      <Col xs="4" key={i}>
                        <Row style={{ paddingBottom: "10px" }}>
                          {i < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i].photoPath,
                                  barangList[i].codeParts,
                                  barangList[i].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i].photoPath}
                                    style={{
                                      height: "30vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "70%",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {barangList[i].codeParts} <br />
                                    <b>{barangList[i].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                          {i + 1 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i + 1].photoPath,
                                  barangList[i + 1].codeParts,
                                  barangList[i + 1].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 1].photoPath}
                                    style={{
                                      height: "30vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "70%",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {barangList[i + 1].codeParts} <br />
                                    <b>{barangList[i + 1].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        {i + 2 < barangList.length ? (
                          <Col
                            role="button"
                            onClick={() =>
                              handleClick(
                                barangList[i + 2].photoPath,
                                barangList[i + 2].codeParts,
                                barangList[i + 2].motor
                              )
                            }
                          >
                            <div className="right-row">
                              <Card
                                style={{
                                  borderRadius: "0",
                                  backgroundColor: "transparent", //#FAFAFA
                                  border: "0px",
                                  marginLeft: "30px",
                                }}
                              >
                                <Card.Img
                                  loading="lazy"
                                  variant="bottom"
                                  src={barangList[i + 2].photoPath}
                                  style={{
                                    height: "30vh",
                                    objectFit: "contain",
                                    paddingTop: "50px",
                                  }}
                                />

                                <Card.Text
                                  style={{
                                    position: "absolute",
                                    left: "1%",
                                    top: "1%",
                                    color: "black",
                                    width: "70%",
                                    fontSize: "15px",
                                  }}
                                >
                                  {barangList[i + 2].codeParts} <br />
                                  <b>{barangList[i + 2].motor}</b>
                                </Card.Text>
                              </Card>
                            </div>
                          </Col>
                        ) : null}
                      </Col>
                      <Col
                        xs="6"
                        key={i + 3}
                        role="button"
                        onClick={() =>
                          handleClick(
                            barangList[i + 3].photoPath,
                            barangList[i + 3].codeParts,
                            barangList[i + 3].motor
                          )
                        }
                      >
                        <div className="left-content">
                          <Card
                            style={{
                              borderRadius: "0",
                              backgroundColor: "transparent", //#FAFAFA
                              border: "0px",
                              marginLeft: "30px",
                            }}
                          >
                            <Card.Img
                              loading="lazy"
                              variant="bottom"
                              src={barangList[i + 3].photoPath}
                              style={{
                                height: "60vh",
                                objectFit: "contain",
                                paddingTop: "50px",
                              }}
                            />

                            <Card.Text
                              style={{
                                position: "absolute",
                                left: "1%",
                                top: "1%",
                                color: "black",
                                width: "70%",
                                fontSize: "15px",
                              }}
                            >
                              {barangList[i + 3].codeParts} <br />
                              <b>{barangList[i + 3].motor}</b>
                            </Card.Text>
                          </Card>
                        </div>
                      </Col>
                      <Col xs="1"></Col>
                    </Row>
                  );
                }

                row++;
                elements.push(element);
              } catch (ex) {
                console.log(ex);
              }
            }
            return elements;
          })()}
        </Container>
      </Col>
    );
  } else {
    return (
      <Col>
        {Header(subkategori.current, isMobile, version)}
        <Container>
          {(() => {
            const elements: JSX.Element[] = [];
            var element: JSX.Element;
            var row = 0;
            for (let i = 0; i < barangList.length; i = i + 4) {
              try {
                if (row % 2 === 0) {
                  element = (
                    <Row style={{ paddingBottom: "10px" }} key={"row" + i}>
                      <Col xs="1"></Col>
                      <Col
                        xs="4"
                        key={i}
                        role="button"
                        onClick={() =>
                          handleClick(
                            barangList[i].photoPath,
                            barangList[i].codeParts,
                            barangList[i].motor
                          )
                        }
                      >
                        <div className="left-content">
                          <Card
                            style={{
                              borderRadius: "0",
                              backgroundColor: "transparent", //#FAFAFA
                              border: "0px",
                              marginLeft: "5px",
                            }}
                          >
                            <Card.Img
                              loading="lazy"
                              variant="bottom"
                              src={barangList[i].photoPath}
                              style={{
                                height: "30vh",
                                objectFit: "contain",
                                paddingTop: "50px",
                              }}
                            />

                            <Card.Text
                              style={{
                                position: "absolute",
                                left: "1%",
                                top: "1%",
                                color: "black",
                                width: "100%",
                                fontSize: "8px",
                              }}
                            >
                              {barangList[i].codeParts} <br />
                              <b>{barangList[i].motor}</b>
                            </Card.Text>
                          </Card>
                        </div>
                      </Col>
                      <Col xs="6" key={i + 1}>
                        {i + 1 < barangList.length ? (
                          <Col
                            style={{ paddingBottom: "10px" }}
                            role="button"
                            onClick={() =>
                              handleClick(
                                barangList[i + 1].photoPath,
                                barangList[i + 1].codeParts,
                                barangList[i + 1].motor
                              )
                            }
                          >
                            <div className="right-row">
                              <Card
                                style={{
                                  borderRadius: "0",
                                  backgroundColor: "transparent", //#FAFAFA
                                  border: "0px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Card.Img
                                  loading="lazy"
                                  variant="bottom"
                                  src={barangList[i + 1].photoPath}
                                  style={{
                                    height: "15vh",
                                    objectFit: "contain",
                                    paddingTop: "50px",
                                    marginLeft: "20%",
                                  }}
                                />

                                <Card.Text
                                  style={{
                                    position: "absolute",
                                    left: "1%",
                                    top: "1%",
                                    color: "black",
                                    width: "100%",
                                    fontSize: "8px",
                                  }}
                                >
                                  {barangList[i + 1].codeParts} <br />
                                  <b>{barangList[i + 1].motor}</b>
                                </Card.Text>
                              </Card>
                            </div>
                          </Col>
                        ) : null}
                        <Row>
                          {i + 2 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() => {
                                handleClick(
                                  barangList[i + 2].photoPath,
                                  barangList[i + 2].codeParts,
                                  barangList[i + 2].motor
                                );
                              }}
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 2].photoPath}
                                    style={{
                                      height: "15vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "100%",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {barangList[i + 2].codeParts} <br />
                                    <b>{barangList[i + 2].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                          {i + 3 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i + 3].photoPath,
                                  barangList[i + 3].codeParts,
                                  barangList[i + 3].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 3].photoPath}
                                    style={{
                                      height: "15vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "100%",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {barangList[i + 3].codeParts} <br />
                                    <b>{barangList[i + 3].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                      <Col xs="1"></Col>
                    </Row>
                  );
                } else {
                  element = (
                    <Row style={{ paddingBottom: "10px" }} key={"row" + i}>
                      <Col xs="1"></Col>
                      <Col xs="4" key={i}>
                        <Row style={{ paddingBottom: "10px" }}>
                          {i < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i].photoPath,
                                  barangList[i].codeParts,
                                  barangList[i].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i].photoPath}
                                    style={{
                                      height: "15vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "100%",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {barangList[i].codeParts} <br />
                                    <b>{barangList[i].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                          {i + 1 < barangList.length ? (
                            <Col
                              xs="6"
                              role="button"
                              onClick={() =>
                                handleClick(
                                  barangList[i + 1].photoPath,
                                  barangList[i + 1].codeParts,
                                  barangList[i + 1].motor
                                )
                              }
                            >
                              <div className="right-row">
                                <Card
                                  style={{
                                    borderRadius: "0",
                                    backgroundColor: "transparent", //#FAFAFA
                                    border: "0px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Card.Img
                                    loading="lazy"
                                    variant="bottom"
                                    src={barangList[i + 1].photoPath}
                                    style={{
                                      height: "15vh",
                                      objectFit: "contain",
                                      paddingTop: "50px",
                                    }}
                                  />

                                  <Card.Text
                                    style={{
                                      position: "absolute",
                                      left: "1%",
                                      top: "1%",
                                      color: "black",
                                      width: "100%",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {barangList[i + 1].codeParts} <br />
                                    <b>{barangList[i + 1].motor}</b>
                                  </Card.Text>
                                </Card>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        {i + 2 < barangList.length ? (
                          <Col
                            role="button"
                            onClick={() =>
                              handleClick(
                                barangList[i + 2].photoPath,
                                barangList[i + 2].codeParts,
                                barangList[i + 2].motor
                              )
                            }
                          >
                            <div className="right-row">
                              <Card
                                style={{
                                  borderRadius: "0",
                                  backgroundColor: "transparent", //#FAFAFA
                                  border: "0px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Card.Img
                                  loading="lazy"
                                  variant="bottom"
                                  src={barangList[i + 2].photoPath}
                                  style={{
                                    height: "15vh",
                                    objectFit: "contain",
                                    paddingTop: "50px",
                                  }}
                                />

                                <Card.Text
                                  style={{
                                    position: "absolute",
                                    left: "1%",
                                    top: "1%",
                                    color: "black",
                                    width: "100%",
                                    fontSize: "8px",
                                  }}
                                >
                                  {barangList[i + 2].codeParts} <br />
                                  <b>{barangList[i + 2].motor}</b>
                                </Card.Text>
                              </Card>
                            </div>
                          </Col>
                        ) : null}
                      </Col>
                      <Col
                        xs="6"
                        key={i + 3}
                        role="button"
                        onClick={() =>
                          handleClick(
                            barangList[i + 3].photoPath,
                            barangList[i + 3].codeParts,
                            barangList[i + 3].motor
                          )
                        }
                      >
                        <div className="left-content">
                          <Card
                            style={{
                              borderRadius: "0",
                              backgroundColor: "transparent", //#FAFAFA
                              border: "0px",
                              marginLeft: "5px",
                            }}
                          >
                            <Card.Img
                              loading="lazy"
                              variant="bottom"
                              src={barangList[i + 3].photoPath}
                              style={{
                                height: "30vh",
                                objectFit: "contain",
                                paddingTop: "50px",
                              }}
                            />

                            <Card.Text
                              style={{
                                position: "absolute",
                                left: "1%",
                                top: "1%",
                                color: "black",
                                width: "100%",
                                fontSize: "8px",
                              }}
                            >
                              {barangList[i + 3].codeParts} <br />
                              <b>{barangList[i + 3].motor}</b>
                            </Card.Text>
                          </Card>
                        </div>
                      </Col>
                      <Col xs="1"></Col>
                    </Row>
                  );
                }

                row++;
                elements.push(element);
              } catch (ex) {
                console.log(ex);
              }
            }
            return elements;
          })()}
        </Container>
      </Col>
    );
  }
}

export default ListProducts;
