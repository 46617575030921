import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

type SearchBarProps = {
  isMobile: boolean;
  isHome: boolean;
};

function searchProcess(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event.key === "Enter") {
    const inputValue = event.currentTarget.value;

    // Perform any desired actions with the input value
    localStorage.setItem("searchString", inputValue);
    window.location.href = "/search";
  }
}

function SearchBar(props: SearchBarProps) {
  return (
    <div>
      {props.isHome ? (
        <Row
          style={props.isMobile ? { marginTop: "2px" } : { marginTop: "40px" }}
        >
          <Col xs={props.isMobile ? "2" : "4"}></Col>
          <Col xs={props.isMobile ? "10" : "8"}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div
                  className="input-group-text"
                  id="basic-addon1"
                  style={{
                    background: "transparent",
                    border: "none",
                    marginTop: props.isMobile ? "3px" : "5px",
                    marginRight: "-20px",
                  }}
                >
                  <BsSearch
                    style={
                      props.isMobile
                        ? { width: "8", height: "8" }
                        : { width: "16", height: "16" }
                    }
                  />
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #000", width: "60%" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-label=""
                  style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                    fontSize: props.isMobile ? "8px" : "16px",
                  }}
                  onKeyDown={searchProcess}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          style={props.isMobile ? { marginTop: "2px" } : { marginTop: "40px" }}
        >
          <Col xs={"12"}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div
                  className="input-group-text"
                  id="basic-addon1"
                  style={{
                    background: "transparent",
                    border: "none",
                    marginTop: props.isMobile ? "3px" : "5px",
                    marginRight: "-20px",
                  }}
                >
                  <BsSearch
                    style={
                      props.isMobile
                        ? { width: "8", height: "8" }
                        : { width: "16", height: "16" }
                    }
                  />
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #000", width: "60%" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-label=""
                  style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                    fontSize: props.isMobile ? "8px" : "16px",
                  }}
                  onKeyDown={searchProcess}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SearchBar;
