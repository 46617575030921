import axios from "axios";
import { BarangModel } from "../Models/BarangModel";
import { SubKategoriModel } from "../Models/SubKategoriModel";
import { SearchModel } from "../Models/SearchModel";



/**
 * Fetches a list of BarangModel objects from the API based on the provided search string.
 *
 * @param {string} searchString - The string to search for in the API.
 * @return {Promise<BarangModel[]>} A promise that resolves to an array of BarangModel objects.
 */
export async function fetchBarang(searchString: string): Promise<BarangModel[]> {
  try {
    searchString = encodeURIComponent(searchString);
    const response = await axios.get<BarangModel[]>(
      `https://www.oribaru.com/api/v1/excel/barang?searchString=${searchString}`, //TODO: GANTI JADI www.oribaru.com BIAR GK BISA DIAKSES DARI LUAR
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Fetches a list of subcategories from an API based on a given search string.
 *
 * @param {string} searchString - The search string used to find subcategories.
 * @return {Promise<SubKategoriModel[]>} A promise that resolves to an array of subcategory models.
 */
export async function fetchSubKategori(searchString: string): Promise<SubKategoriModel[]> {
  try {
    searchString = encodeURIComponent(searchString);
    const response = await axios.get<SubKategoriModel[]>(
      `https://www.oribaru.com/api/v1/excel/subkategori?searchString=${searchString}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Retrieves a list of BarangModel objects based on the provided kategori and subkategori values.
 *
 * @param {string} kategori - The kategori value used to filter the BarangModel objects.
 * @param {string} subkategori - The subkategori value used to filter the BarangModel objects.
 * @return {Promise<BarangModel[]>} A Promise that resolves to an array of BarangModel objects that match the provided kategori and subkategori values.
 */
export async function fetchBarangFromSubKategori(kategori: string, subkategori: string): Promise<BarangModel[]> {
  try {
    kategori = encodeURIComponent(kategori);
    subkategori = encodeURIComponent(subkategori);
    const response = await axios.get<BarangModel[]>(
      `https://www.oribaru.com/api/v1/excel/subkategori-barang?kategori=${kategori}&subkategori=${subkategori}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}


/**
 * Search for products based on a given search string.
 *
 * @param {string} searchString - The search string used to find products.
 * @return {Promise<BarangModel[]>} - A promise that resolves to an array of BarangModel objects representing the found products.
 */
export async function searchProducts (searchString: string): Promise<SearchModel[]> {
  try {
    searchString = encodeURIComponent(searchString);
    const response = await axios.get<SearchModel[]>(
      `https://www.oribaru.com/api/v1/excel/search?searchString=${searchString}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
