import { BrowserRouter, Route, Routes } from "react-router-dom";
import Categories from "./Views/Categories/Categories";
import ListSubCategories from "./Views/SubCategories/ListSubCategories";
import ListProducts from "./Views/Products/ListProducts";
import SearchResult from "./Views/SearchResult/SearchResult";

/**
 * Renders the main component of the application, which includes the router and the navigation routes.
 *
 * @return {JSX.Element} The main component of the application.
 */
function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Categories />} />
        <Route path="/subcategories" element={<ListSubCategories />} />
        <Route path="/products" element={<ListProducts />} />
        <Route path="/search" element={<SearchResult />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
