import axios from "axios";

/**
 * Get Version from API appsettings.json
 */
export async function getCurrentPhotoVersion(): Promise<string> {
    try {
      const response = await axios.get<string>(
        `https://www.oribaru.com/api/v1/version`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return "version_error";
    }
  }