import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "./ListSubCategories.css";
import { fetchSubKategori } from "../../Controllers/ExcelController";
import { SubKategoriModel } from "../../Models/SubKategoriModel";
import { Link } from "react-router-dom";
import LoadingComponent from "../Common/Loading";
import SearchBar from "../Categories/SearchBar";

/**
 * Render the header component.
 *
 * @return {JSX.Element} The header component JSX.
 */
function Header(
  subtitle: string,
  isMobile: boolean,
  version: string
): JSX.Element {
  return (
    <Row style={{ marginBottom: "20px" }}>
      <Col
        xs="12"
        style={{
          backgroundColor: "#FFD000",
          padding: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col xs="1"></Col>
        <Link to="/">
          <img
            loading="lazy"
            src={`/assets/images/Oribaru.png?version=${version}`}
            alt="Logo"
            style={{ width: "70px", height: "50px", marginRight: "10px" }}
          />
        </Link>
        <div
          style={{
            textAlign: "center",
            flexGrow: "1",
            marginLeft: isMobile ? "0" : "30px",
          }}
        >
          <h6
            style={{
              margin: "0",
              marginBottom: "10px",
              fontSize: isMobile ? "14px" : "20px",
            }}
          >
            PRODUCT SUB-CATEGORY
          </h6>
          <h2 style={{ margin: "0", fontSize: isMobile ? "20px" : "" }}>
            {subtitle}
          </h2>
        </div>
        <SearchBar isMobile={isMobile} isHome={false} />
      </Col>
    </Row>
  );
}

/**
 * Renders a list of subcategories as cards in a container.
 *
 * @return {JSX.Element} The React component for rendering the subcategory list.
 */
function ListSubCategories() {
  const [subKategoriList, setSubKategoriList] = useState<SubKategoriModel[]>(
    []
  );
  const [hoveredId, setHoveredId] = useState(-1);
  const [isMobile, setIsMobile] = useState(false);
  const kategoriStorage = useRef("");
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState("");

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // adjust the threshold value as needed
    }

    window.addEventListener("resize", handleResize);

    handleResize(); // initialize the state value on mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (id: number) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(-1);
  };

  const handleClick = (subkategori: string) => {
    localStorage.setItem("subKategoriStorage", subkategori);
  };

  useEffect(() => {
    const getKategoriStorage = () => {
      const kategori = localStorage.getItem("kategoriStorage");
      if (kategori !== null) {
        kategoriStorage.current = kategori;
      }
    };
    const fetchSubKategoriList = async () => {
      const result = await fetchSubKategori(kategoriStorage.current);
      setSubKategoriList(result);
    };

    const getCurrentVersionFromStorage = async () => {
      var versionStorage = localStorage.getItem("photoVersion");
      if (versionStorage !== null) {
        setVersion(versionStorage);
      }
    };

    getCurrentVersionFromStorage();
    getKategoriStorage();
    fetchSubKategoriList();
    setIsLoading(false);
  }, [version]);

  var listItems = [];

  if (isLoading) {
    return <LoadingComponent />;
  }

  for (let i = 0; i < subKategoriList.length; i = i + 2) {
    var element: JSX.Element;
    if (!isMobile) {
      element = (
        <Row key={"row" + i}>
          <Col md="1"></Col>
          <Col
            md="5" // Added to take up half width on larger screens
            key={i}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(subKategoriList[i].subKategori)}
            style={{
              backgroundColor: i === hoveredId ? "#FFD000" : "#FAFAFA",
              padding: 10,
              margin: 10,
              borderRadius: 5,
            }}
          >
            <a href="products">
              <Card
                style={{
                  borderRadius: "0",
                  backgroundColor: "transparent", //#FAFAFA
                  border: "0px",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "30px",
                  height: "40vh",
                }}
              >
                <Card.Img
                  loading="lazy"
                  variant="top"
                  src={subKategoriList[i].photoPath}
                  style={{
                    height: "30vh",
                    objectFit: "contain",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    marginLeft: "-20%",
                  }}
                />

                <h3>
                  <Card.Text
                    style={{
                      position: "absolute",
                      right: "5%",
                      bottom: "1%",
                      color: "black",
                      width: "70%",
                      textAlign: "right",
                      fontSize: "20px",
                    }}
                  >
                    {subKategoriList[i].subKategori}
                  </Card.Text>
                </h3>
              </Card>
            </a>
          </Col>
          {i + 1 < subKategoriList.length ? (
            <Col
              md="5" // Added to take up half width on larger screens
              key={i + 1}
              onMouseEnter={() => handleMouseEnter(i + 1)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(subKategoriList[i + 1].subKategori)}
              style={{
                backgroundColor: i + 1 === hoveredId ? "#FFD000" : "#FAFAFA",
                padding: 10,
                margin: 10,
                borderRadius: 5,
              }}
            >
              <a href="products">
                <Card
                  style={{
                    borderRadius: "0",
                    backgroundColor: "transparent", //#FAFAFA
                    border: "0px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "30px",
                    height: "40vh",
                  }}
                >
                  <Card.Img
                    loading="lazy"
                    variant="top"
                    src={subKategoriList[i + 1].photoPath}
                    style={{
                      height: "30vh",
                      objectFit: "contain",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      marginLeft: "-20%",
                    }}
                  />

                  <h3>
                    <Card.Text
                      style={{
                        position: "absolute",
                        right: "5%",
                        bottom: "1%",
                        color: "black",
                        width: "70%",
                        textAlign: "right",
                        fontSize: "20px",
                      }}
                    >
                      {subKategoriList[i + 1].subKategori}
                    </Card.Text>
                  </h3>
                </Card>
              </a>
            </Col>
          ) : null}
          <Col md="1"></Col>
        </Row>
      );
    } else {
      element = (
        <Row key={"row" + i} style={{ marginBottom: 10 }}>
          <Col xs="1"></Col>
          <Col
            xs="5" // Added to take up half width on larger screens
            key={i}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(subKategoriList[i].subKategori)}
            style={{
              backgroundColor: i === hoveredId ? "#FFD000" : "#FAFAFA",
              marginRight: 5,
              marginLeft: -5,
              borderRadius: 5,
            }}
          >
            <a href="products">
              <Card
                style={{
                  borderRadius: "0",
                  backgroundColor: "transparent", //#FAFAFA
                  border: "0px",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "30px",
                  height: "40vh",
                }}
              >
                <Card.Img
                  loading="lazy"
                  variant="top"
                  src={subKategoriList[i].photoPath}
                  style={{
                    height: "30vh",
                    objectFit: "contain",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    marginLeft: "-20%",
                  }}
                />

                <h3>
                  <Card.Text
                    style={{
                      position: "absolute",
                      right: "5%",
                      bottom: "1%",
                      color: "black",
                      width: "100%",
                      textAlign: "right",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {subKategoriList[i].subKategori}
                  </Card.Text>
                </h3>
              </Card>
            </a>
          </Col>
          {i + 1 < subKategoriList.length ? (
            <Col
              xs="5" // Added to take up half width on larger screens
              key={i + 1}
              onMouseEnter={() => handleMouseEnter(i + 1)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(subKategoriList[i + 1].subKategori)}
              style={{
                backgroundColor: i + 1 === hoveredId ? "#FFD000" : "#FAFAFA",
                borderRadius: 5,
              }}
            >
              <a href="products">
                <Card
                  style={{
                    borderRadius: "0",
                    backgroundColor: "transparent", //#FAFAFA
                    border: "0px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "30px",
                    height: "40vh",
                  }}
                >
                  <Card.Img
                    loading="lazy"
                    variant="top"
                    src={subKategoriList[i + 1].photoPath}
                    style={{
                      height: "30vh",
                      objectFit: "contain",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      marginLeft: "-20%",
                    }}
                  />

                  <h3>
                    <Card.Text
                      style={{
                        position: "absolute",
                        right: "5%",
                        bottom: "1%",
                        color: "black",
                        width: "100%",
                        textAlign: "right",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {subKategoriList[i + 1].subKategori}
                    </Card.Text>
                  </h3>
                </Card>
              </a>
            </Col>
          ) : null}
          <Col xs="1"></Col>
        </Row>
      );
    }

    listItems.push(element);
  }

  return (
    <Col>
      {Header(kategoriStorage.current, isMobile, version)}
      <Container>{listItems}</Container>
    </Col>
  );
}

export default ListSubCategories;
