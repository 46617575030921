import "./Categories.css";
import { Col, Row, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import { getCurrentPhotoVersion } from "../../Controllers/VersionController";

var version = "";

/**
 * Renders a row for two columns containing an image title and a title.
 *
 * @param {string} imageTitle - The image title for the row.
 * @param {string} title - The title for the row.
 * @param {string} fontSize - The font size for the row.
 * @return {JSX.Element} The JSX element for the row with two columns.
 */
function Row2Cols(
  imageTitle: string,
  title: string,
  fontSize: string
): JSX.Element {
  imageTitle = `/assets/images/CATEGORY/${imageTitle}?version=${version}`;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (kategori: string) => {
    localStorage.setItem("kategoriStorage", kategori);
  };

  return (
    <Col xs="5" onClick={() => handleClick(title)}>
      <a
        href="subcategories"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: isHovered ? "#FFD000" : "#FAFAFA",
            border: "0px",
          }}
        >
          <Card.Img
            loading="lazy"
            variant="top"
            src={imageTitle}
            style={{
              height: "30vh",
              objectFit: "contain",
              marginTop: "15px",
              marginBottom: "30px",
            }}
          />

          <Card.Text
            style={{
              position: "absolute",
              left: "5%",
              bottom: "5%",
              color: "#282c34",
              fontSize: fontSize,
            }}
          >
            <b>{title}</b>
          </Card.Text>
        </Card>
      </a>
    </Col>
  );
}

/**
 * Renders a row for three columns containing an image title and a title (Left part. col-xs-5).
 *
 * @param {string} imageTitle - The image title for the row.
 * @param {string} title - The title for the row.
 * @param {string} fontSize - The font size for the row.
 * @return {JSX.Element} The JSX element for the row with two columns.
 */
function Row3ColsLeft(
  imageTitle: string,
  title: string,
  fontSize: string
): JSX.Element {
  imageTitle = `/assets/images/CATEGORY/${imageTitle}?version=${version}`;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (kategori: string) => {
    localStorage.setItem("kategoriStorage", kategori);
  };

  return (
    <Col xs="5" onClick={() => handleClick(title)}>
      <a
        href="subcategories"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: isHovered ? "#FFD000" : "#FAFAFA",
            border: "0px",
          }}
        >
          <Card.Img
            loading="lazy"
            variant="top"
            src={imageTitle}
            style={{
              height: "30vh",
              objectFit: "contain",
              marginTop: "15px",
              marginBottom: "30px",
            }}
          />

          <Card.Text
            style={{
              position: "absolute",
              left: "5%",
              bottom: "5%",
              color: "#282c34",
              fontSize: fontSize,
            }}
          >
            <b>{title}</b>
          </Card.Text>
        </Card>
      </a>
    </Col>
  );
}

/**
 * Renders a row for three columns containing an image title and a title (right part. Half of col-xs-5).
 *
 * @param {string} imageTitle - The image title for the row.
 * @param {string} title - The title for the row.
 * @param {string} fontSize - The font size for the row.
 * @return {JSX.Element} The JSX element for the row with two columns.
 */
function Row3ColsRight(
  imageTitle: string,
  title: string,
  fontSize: string
): JSX.Element {
  imageTitle = `/assets/images/CATEGORY/${imageTitle}?version=${version}`;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (kategori: string) => {
    localStorage.setItem("kategoriStorage", kategori);
  };

  return (
    <Col xs="6" onClick={() => handleClick(title)}>
      <a
        href="subcategories"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: isHovered ? "#FFD000" : "#FAFAFA",
            border: "0px",
          }}
        >
          <Card.Img
            loading="lazy"
            variant="top"
            src={imageTitle}
            style={{
              height: "30vh",
              objectFit: "contain",
              marginTop: "15px",
              marginBottom: "30px",
            }}
          />

          <Card.Text
            style={{
              position: "absolute",
              left: "5%",
              bottom: "5%",
              color: "#282c34",
              fontSize: fontSize,
            }}
          >
            <b>{title}</b>
          </Card.Text>
        </Card>
      </a>
    </Col>
  );
}

function Categories() {
  const [isBurgerMenuClicked, setIsBurgerMenuClicked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isApiReady, setApiReady] = useState(false);

  const handleBurgerMenuClicked = () => {
    setIsBurgerMenuClicked(!isBurgerMenuClicked);
  };

  const handleClick = (kategori: string) => {
    localStorage.setItem("kategoriStorage", kategori);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600); // adjust the threshold value as needed
    }

    function handleClickOutsideOverlay(event: Event) {
      const overlay = document.querySelector(".overlay");
      const burgerMenu = document.querySelector(".burger-menu");
      if (
        overlay !== null &&
        !overlay.contains(event.target as Node) &&
        burgerMenu !== null &&
        !burgerMenu.contains(event.target as Node)
      ) {
        setIsBurgerMenuClicked(false);
      }
    }

    const getCurrentVersion = async () => {
      version = await getCurrentPhotoVersion();
      localStorage.setItem("photoVersion", version);
      setApiReady(true);
    };

    getCurrentVersion();
    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleClickOutsideOverlay);

    handleResize(); // initialize the state value on mount

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClickOutsideOverlay);
    };
  }, [isApiReady]);

  const fontSize = isMobile ? "8px" : "16px";
  const fontSizeBurgerMenu = isMobile ? "10px" : "14px";
  const fontSizeBurgerTitle = isMobile ? "px" : "18px";

  return (
    <div className="App">
      <div>
        <Col xs="12">
          <div className="burger-menu-container">
            <Row>
              <Col xs="6" style={{ textAlign: "left" }}>
                <div
                  className={`burger-menu ${isBurgerMenuClicked ? "open" : ""}`}
                  onClick={handleBurgerMenuClicked}
                  style={
                    isMobile
                      ? {
                          marginLeft: "15px",
                          marginTop: "5px",
                          fontSize: "15px",
                        }
                      : {
                          marginLeft: "60px",
                          marginTop: "40px",
                          fontSize: "25px",
                        }
                  }
                >
                  ☰
                </div>
              </Col>
              <Col xs="6" style={{ textAlign: "right" }}>
                <SearchBar isMobile={isMobile} isHome={true} />
              </Col>
            </Row>
          </div>
        </Col>

        {isBurgerMenuClicked && (
          <>
            <div className="overlay" onClick={handleBurgerMenuClicked}></div>
            <div className="menu" style={{ marginTop: "30px" }}>
              <div
                style={{
                  fontSize: fontSizeBurgerTitle,
                  fontWeight: "bold",
                  marginBottom: "40px",
                }}
              >
                {isMobile ? (
                  <h6>PRODUCT CATEGORY</h6>
                ) : (
                  <h3>PRODUCT CATEGORY</h3>
                )}
              </div>
              <Row>
                <Col xs="2"></Col>
                <Col xs="8">
                  <a
                    href="subcategories"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("KELISTRIKAN")}
                    >
                      KELISTRIKAN
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("CVT MOTOR MATIC")}
                    >
                      CVT MOTOR MATIC
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SISTEM PENGEREMAN")}
                    >
                      SISTEM PENGEREMAN
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SISTEM MESIN UTAMA")}
                    >
                      SISTEM MESIN UTAMA
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("AXLE PRODUCT")}
                    >
                      AXLE PRODUCT
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SISTEM KOPLING")}
                    >
                      SISTEM KOPLING
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("KUNCI KONTAK")}
                    >
                      KUNCI KONTAK
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("GASOLINE SISTEM")}
                    >
                      GASOLINE SISTEM
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("STARTER SYSTEM")}
                    >
                      STARTER SYSTEM
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SUKU CADANG PENDUKUNG")}
                    >
                      SUKU CADANG PENDUKUNG
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("BAGIAN RODA KEMUDI")}
                    >
                      BAGIAN RODA KEMUDI
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SUSPENSION SISTEM")}
                    >
                      SUSPENSION SISTEM
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("SPEEDOMETER PARTS")}
                    >
                      SPEEDOMETER PARTS
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("HEADLIGHTS LAMP")}
                    >
                      HEADLIGHTS LAMP
                    </div>
                    <div
                      style={{
                        fontSize: fontSizeBurgerMenu,
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                      onClick={() => handleClick("INNER DAN CABLE PARTS")}
                    >
                      INNER DAN CABLE PARTS
                    </div>
                  </a>
                </Col>
                <Col xs="2"></Col>
              </Row>
            </div>
          </>
        )}

        {isBurgerMenuClicked && (
          <>
            <div className="overlay-all"></div>
          </>
        )}

        <a href="#main-content" className="scroll-link">
          <img
            loading="lazy"
            src={`/assets/images/cover_image.png?version=${version}`}
            alt="Gambar cover"
            className="back-image"
            style={{ zIndex: 0 }}
          />
        </a>

        <section id="main-content">
          <Col
            xs="12"
            className="justify-content-center"
            style={{ marginTop: "70px", marginBottom: "70px" }}
          >
            <h2>PRODUCT CATEGORY</h2>
          </Col>
          <Container>
            <div>
              {/* 2 row pertama */}
              <div>
                <Row>
                  <Col xs="1"></Col>
                  {Row2Cols(
                    "KELISTRIKAN_SAKLARLAMPU.png",
                    "KELISTRIKAN",
                    fontSize
                  )}
                  {Row2Cols(
                    "CVTMOTORMATIC_PEMBERATPULLY.png",
                    "CVT MOTOR MATIC",
                    fontSize
                  )}
                  <Col xs="1"></Col>
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="1"></Col>
                  {Row3ColsLeft(
                    "SISTEMPENGEREMAN_CALIPERASSY.png",
                    "SISTEM PENGEREMAN",
                    fontSize
                  )}
                  <Col xs="5">
                    <Row>
                      {Row3ColsRight(
                        "SISTEMMESINUTAMA_BLOKSEHER.png",
                        "SISTEM MESIN UTAMA",
                        fontSize
                      )}
                      {Row3ColsRight(
                        "Halfshaft-6_CrossGlide_Welded-Tubular-Axle_CrossGlide_Large-1.png",
                        "AXLE PRODUCT",
                        fontSize
                      )}
                    </Row>
                  </Col>
                  <Col xs="1"></Col>
                </Row>
              </div>
              {/* 2 row kedua */}
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="1"></Col>
                  {Row2Cols(
                    "SISTEMKOPLING_RUMAHPELORSTARTERSET.png",
                    "SISTEM KOPLING",
                    fontSize
                  )}
                  {Row2Cols("IMG_0149.png", "KUNCI KONTAK", fontSize)}
                  <Col xs="1"></Col>
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="1"></Col>
                  {Row3ColsLeft(
                    "GASOLINESYSTEM_KARBURATOR_CVR.png",
                    "GASOLINE SISTEM",
                    fontSize
                  )}
                  <Col xs="5">
                    <Row>
                      {Row3ColsRight(
                        "STARTERSYSTEM_RELAYSTARTER.png",
                        "STARTER SYSTEM",
                        fontSize
                      )}
                      {Row3ColsRight(
                        "SUKUCADANGPENDUKUNG_POMPAAIRRADIATOR.png",
                        "SUKU CADANG PENDUKUNG",
                        fontSize
                      )}
                    </Row>
                  </Col>
                  <Col xs="1"></Col>
                </Row>
              </div>
              {/* 2 row ketiga */}
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="1"></Col>
                  {Row2Cols(
                    "BAGIANRODAKEMUDI_NAPGEAR.png",
                    "BAGIAN RODA KEMUDI",
                    fontSize
                  )}
                  {Row2Cols(
                    "SUSPENSIONSYSTSTEM_BOSHARM.png",
                    "SUSPENSION SISTEM",
                    fontSize
                  )}
                  <Col xs="1"></Col>
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="1"></Col>
                  {Row3ColsLeft(
                    "Speedometer-Car-PNG-File.png",
                    "SPEEDOMETER PARTS",
                    fontSize
                  )}
                  <Col xs="5">
                    <Row>
                      {Row3ColsRight(
                        "56-569785_headlights-car-front-light-png.png",
                        "HEADLIGHTS LAMP",
                        fontSize
                      )}
                      {Row3ColsRight(
                        "1_1.png",
                        "INNER DAN CABLE PARTS",
                        fontSize
                      )}
                    </Row>
                  </Col>
                  <Col xs="1"></Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Categories;
