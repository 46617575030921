import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { SearchModel } from "../../Models/SearchModel";
import { searchProducts } from "../../Controllers/ExcelController";
import { Link } from "react-router-dom";
import LoadingComponent from "../Common/Loading";
import swal from "sweetalert2";
import SearchBar from "../Categories/SearchBar";

var apiResult: SearchModel[] = [];
var folders: SearchModel[] = [];
var products: SearchModel[] = [];
var searchString: string | null = "";
var isMobile: boolean = false;

const NoResultWidget = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        textAlign: "center",
        color: "grey",
      }}
    >
      <h4>Keyword yang anda cari tidak ditemukan :(</h4> 
    </div>
  );
};

async function init() {
  searchString = localStorage.getItem("searchString");

  if (window.innerWidth < 768) {
    isMobile = true;
  }

  if (searchString != null) {
    searchString = searchString.toUpperCase();
    apiResult = await searchProducts(searchString);
  }

  for (let i = 0; i < apiResult.length; i++) {
    if (apiResult[i].isSubCategories) {
      folders.push(apiResult[i]);
    } else {
      products.push(apiResult[i]);
    }
  }
}

/**
 * Render the header component.
 *
 * @return {JSX.Element} The header component JSX.
 */
function Header(version: string): JSX.Element {
  return (
    <Row style={{ marginBottom: "20px" }}>
      <Col
        xs="12"
        style={{
          backgroundColor: "#FFD000",
          padding: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col xs="1"></Col>
        <Link to="/">
          <img
            loading="lazy"
            src={`/assets/images/Oribaru.png?version=${version}`}
            alt="Logo"
            style={{ width: "70px", height: "50px", marginRight: "10px" }}
          />
        </Link>
        <div style={{ textAlign: "center", flexGrow: "1" }}>
          <h6 style={{ margin: "0", marginBottom: "10px", fontSize: "20px" }}>
            SEARCH RESULT
          </h6>
          <h1 style={{ margin: "0" }}>"{searchString}"</h1>
        </div>
        <SearchBar isMobile={isMobile} isHome={false} />
      </Col>
    </Row>
  );
}

function SearchSubCategoriesResult() {
  var elements: JSX.Element[] = [];

  const handleClick = (kategori: string, subKategori: string) => {
    localStorage.setItem("kategoriStorage", kategori);
    localStorage.setItem("subKategoriStorage", subKategori);
  };

  for (let i = 0; i < folders.length; i++) {
    elements.push(
      <Row
        key={
          folders[i].barang.kategori +
          folders[i].barang.nama +
          folders[i].barang.codeParts
        }
        style={{ marginTop: "20px" }}
      >
        <Col xs="1"></Col>
        <Col
          xs="10"
          style={{ backgroundColor: "#FAFAFA" }}
          onClick={() =>
            handleClick(folders[i].barang.kategori, folders[i].barang.nama)
          }
        >
          <a href="products" style={{ color: "black", textDecoration: "none" }}>
            <Row>
              <Col xs={isMobile ? "10" : "11"}>
                <Container>
                  <h3 style={{ paddingTop: "20px" }}>
                    {folders[i].barang.nama}
                  </h3>
                  <p
                    style={{
                      color: "gray",
                      paddingBottom: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    {folders[i].barang.kategori}
                  </p>
                </Container>
              </Col>
              <Col
                xs={isMobile ? "2" : "1"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isMobile ? (
                  <FaChevronRight fontSize={"20px"} />
                ) : (
                  <Container>
                    <FaChevronRight fontSize={"20px"} />
                  </Container>
                )}
              </Col>
            </Row>
          </a>
        </Col>
        <Col xs="1"></Col>
      </Row>
    );
  }

  return (
    <div>
      <Container>
        {folders.length > 0 ? (
          <Row>
            <Col xs="1"></Col>
            <Col xs="11" style={{ paddingLeft: "0px" }}>
              <h3>SUB-CATEGORY</h3>
            </Col>
          </Row>
        ) : null}
        {elements}
      </Container>
    </div>
  );
}

function ListProducts() {
  const [hoveredId, setHoveredId] = useState(-1);

  const handleMouseEnter = (id: number) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(-1);
  };

  const handleClick = (photoPath: string, codeParts: string, motor: string) => {
    var htmlTag: string;

    htmlTag = `
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <img loading="lazy" src="${photoPath}" style="width: 80%; margin-bottom: 20px;" />
      <div style="text-align: center;">
        ${codeParts} <br />
        <b>${motor}</b>
      </div>
    </div>
  `;

    if (isMobile) {
      htmlTag = `
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <img loading="lazy" src="${photoPath}" style="height: 100%;" />
      <div style="text-align: center;">
        ${codeParts} <br />
        <b>${motor}</b>
      </div>
    </div>
  `;
    }

    swal.fire({
      html: htmlTag,
      showCloseButton: true,
      showConfirmButton: false,
      heightAuto: false,
    });
  };

  var listItems = [];

  for (let i = 0; i < products.length; i = i + 2) {
    var element: JSX.Element;
    if (!isMobile) {
      element = (
        <Row key={"row" + i}>
          <Col
            md="6" // Added to take up half width on larger screens
            key={i}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() =>
              handleClick(
                products[i].barang.photoPath,
                products[i].barang.codeParts,
                products[i].barang.motor
              )
            }
            style={{
              marginTop: 10,
              borderRadius: 5,
              paddingLeft: "0px",
              zIndex: 99,
            }}
            role="button"
          >
            <div
              style={{
                backgroundColor: i === hoveredId ? "#FFD000" : "#FAFAFA",
              }}
            >
              <Card
                style={{
                  borderRadius: "0",
                  backgroundColor: "transparent", //#FAFAFA
                  border: "0px",
                  marginTop: "15px",
                  marginBottom: "15px",

                  height: "40vh",
                }}
              >
                <Card.Img
                  loading="lazy"
                  variant="top"
                  src={products[i].barang.photoPath}
                  style={{
                    height: "40vh",
                    objectFit: "contain",
                    paddingBottom: "20px",
                    marginLeft: "-20%",
                  }}
                />

                <h3>
                  <Card.Text
                    style={{
                      position: "absolute",
                      right: "5%",
                      bottom: "1%",
                      width: "50%",
                      textAlign: "right",
                      fontSize: "20px",
                    }}
                  >
                    <span style={{ color: "#6D6D6D", margin: 0 }}>
                      {products[i].barang.kategori} <br />
                      {products[i].barang.nama} <br />
                    </span>
                    <span style={{ color: "black" }}>
                      {products[i].barang.codeParts} <br />
                      {products[i].barang.motor}
                    </span>
                  </Card.Text>
                </h3>
              </Card>
            </div>
          </Col>
          {i + 1 < products.length ? (
            <Col
              md="6" // Added to take up half width on larger screens
              key={i + 1}
              onMouseEnter={() => handleMouseEnter(i + 1)}
              onMouseLeave={handleMouseLeave}
              onClick={() =>
                handleClick(
                  products[i + 1].barang.photoPath,
                  products[i + 1].barang.codeParts,
                  products[i + 1].barang.motor
                )
              }
              style={{
                marginTop: 10,
                borderRadius: 5,
                paddingRight: "0px",
              }}
              role="button"
            >
              <div
                style={{
                  backgroundColor: i + 1 === hoveredId ? "#FFD000" : "#FAFAFA",
                  padding: 0,
                }}
              >
                <Card
                  style={{
                    borderRadius: "0",
                    backgroundColor: "transparent", //#FAFAFA
                    border: "0px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    height: "40vh",
                  }}
                >
                  <Card.Img
                    loading="lazy"
                    variant="top"
                    src={products[i + 1].barang.photoPath}
                    style={{
                      height: "40vh",
                      objectFit: "contain",
                      paddingBottom: "20px",
                      marginLeft: "-20%",
                    }}
                  />

                  <h3>
                    <Card.Text
                      style={{
                        position: "absolute",
                        right: "5%",
                        bottom: "1%",
                        width: "50%",
                        textAlign: "right",
                        fontSize: "20px",
                      }}
                    >
                      <span style={{ color: "#6D6D6D", margin: 0 }}>
                        {products[i + 1].barang.kategori} <br />
                        {products[i + 1].barang.nama} <br />
                      </span>
                      <span style={{ color: "black" }}>
                        {products[i + 1].barang.codeParts} <br />
                        {products[i + 1].barang.motor}
                      </span>
                    </Card.Text>
                  </h3>
                </Card>
              </div>
            </Col>
          ) : null}
        </Row>
      );
    } else {
      element = (
        <Row key={"row" + i} style={{ marginBottom: 10 }}>
          <Col
            xs="6" // Added to take up half width on larger screens
            key={i}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() =>
              handleClick(
                products[i].barang.photoPath,
                products[i].barang.codeParts,
                products[i].barang.motor
              )
            }
            style={{
              paddingLeft: "-5px",
              borderRadius: 5,
              zIndex: 99,
            }}
            role="button"
          >
            <div
              style={{
                backgroundColor: i === hoveredId ? "#FFD000" : "#FAFAFA",
              }}
            >
              <Card
                style={{
                  borderRadius: "0",
                  backgroundColor: "transparent", //#FAFAFA
                  border: "0px",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "30px",
                  height: "40vh",
                }}
              >
                <Card.Img
                  loading="lazy"
                  variant="top"
                  src={products[i].barang.photoPath}
                  style={{
                    height: "40vh",
                    objectFit: "contain",
                    paddingBottom: "20px",
                    marginLeft: "-20%",
                  }}
                />

                <h3>
                  <Card.Text
                    style={{
                      position: "absolute",
                      right: "5%",
                      bottom: "1%",
                      width: "100%",
                      textAlign: "right",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ color: "#6D6D6D", margin: 0 }}>
                      {products[i].barang.kategori} <br />
                      {products[i].barang.nama} <br />
                    </span>
                    <span style={{ color: "black" }}>
                      {products[i].barang.codeParts} <br />
                      {products[i].barang.motor}
                    </span>
                  </Card.Text>
                </h3>
              </Card>
            </div>
          </Col>
          {i + 1 < products.length ? (
            <Col
              xs="6" // Added to take up half width on larger screens
              key={i + 1}
              onMouseEnter={() => handleMouseEnter(i + 1)}
              onMouseLeave={handleMouseLeave}
              onClick={() =>
                handleClick(
                  products[i + 1].barang.photoPath,
                  products[i + 1].barang.codeParts,
                  products[i + 1].barang.motor
                )
              }
              style={{
                paddingRight: "-5px",
                borderRadius: 5,
              }}
              role="button"
            >
              <div
                style={{
                  backgroundColor: i === hoveredId ? "#FFD000" : "#FAFAFA",
                }}
              >
                <Card
                  style={{
                    borderRadius: "0",
                    backgroundColor: "transparent", //#FAFAFA
                    border: "0px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "30px",
                    height: "40vh",
                  }}
                >
                  <Card.Img
                    loading="lazy"
                    variant="top"
                    src={products[i + 1].barang.photoPath}
                    style={{
                      height: "40vh",
                      objectFit: "contain",
                      paddingBottom: "20px",
                      marginLeft: "-20%",
                    }}
                  />

                  <h3>
                    <Card.Text
                      style={{
                        position: "absolute",
                        right: "5%",
                        bottom: "1%",
                        width: "100%",
                        textAlign: "right",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "#6D6D6D", margin: 0 }}>
                        {products[i + 1].barang.kategori} <br />
                        {products[i + 1].barang.nama} <br />
                      </span>
                      <span style={{ color: "black" }}>
                        {products[i + 1].barang.codeParts} <br />
                        {products[i + 1].barang.motor}
                      </span>
                    </Card.Text>
                  </h3>
                </Card>
              </div>
            </Col>
          ) : null}
        </Row>
      );
    }

    listItems.push(element);
  }
  return (
    <div>
      {products.length > 0 ? (
        <Container style={{ marginTop: isMobile ? "30px" : "80px" }}>
          <Row>
            <Col xs="1"></Col>
            <Col xs="10" style={{ paddingLeft: "0px" }}>
              <h3>PRODUCTS</h3>
            </Col>
            <Col xs="1"></Col>
          </Row>
        </Container>
      ) : null}
      <Container>
        <Row>
          <Col xs="1"></Col>
          <Col xs="10">{listItems}</Col>
          <Col xs="1"></Col>
        </Row>
      </Container>
    </div>
  );
}

function SearchApiResult() {
  const [isApiReady, setApiReady] = useState(false);
  const [version, setVersion] = useState("");

  useEffect(() => {
    const getCurrentVersionFromStorage = async () => {
      var versionStorage = localStorage.getItem("photoVersion");
      if (versionStorage !== null) {
        setVersion(versionStorage);
      }
    };

    const initSearch = async () => {
      await init();
      await getCurrentVersionFromStorage();
      setApiReady(true);
    };

    initSearch();
  }, [version]);

  return (
    <div>
      {isApiReady ? (
        <div>
          {Header(version)}
          {folders.length > 0 || products.length > 0 ? ( //If product or folders exist, show results
            <div>
              <SearchSubCategoriesResult />
              <ListProducts />
            </div>
          ) : (
            //Show no result
            <NoResultWidget />
          )}
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
}

export default SearchApiResult;
